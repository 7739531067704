<template>
  <div v-if="!user.email_verified_at" class="card">
    <div class="card-body">
      <AccountUnverified/>
    </div>
  </div>
  <div v-else class="Profile">
    <account-profile />
  </div>
</template>

<script>
import AccountProfile from '@/components/Account/Profile/Profile.vue'

export default {
  components: {
    AccountProfile
  },

  metaInfo: {
    title: 'Profile'
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
